export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "调拨单号",
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "出库仓库",
    dataIndex: "out_warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "入库仓库",
    dataIndex: "in_warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 180,
    scopedSlots: { customRender: "action" },
  },
];
