<template>
  <div>
    <a-card title="调拨单据">
      <a-row :gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" placeholder="客户" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="单据编号, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="8" :xl="4" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button icon="download" @click="summaryExportExcel">汇总导出</a-button>
            <a-button @click="printOrder">打印调拨单</a-button>
          </a-button-group>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handleAdd(form)">新增调拨单</a-button>
        </div>
      </a-row>

      <div>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="onChangeTable"
          :scroll="{ x: 1020, y: 480 }"
        >
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detail(item)">详情</a-button>
              <a-button type="primary" @click="copyOrder(item)">复制</a-button>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger">作废</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { exportExcel } from "@/utils/excel";
import { stockTransferOrdersExport, stockTransferOrdersSummaryExport } from "@/api/export";
import { stockTransferOrderList, stockTransferOrderVoid, stockTransferOrderPrint } from "@/api/stockTransfer";
import { columns } from "./columns";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import NP from "number-precision";
import JsBarcode from "jsbarcode";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      searchForm: { page: 1, is_void: false },
      loading: false,
      items: [],
      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
    },
    handleAdd() {
      this.$router.push({ path: "/stock_transfer/order_create", query: {} });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      stockTransferOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voidItem(item) {
      stockTransferOrderVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
    copyOrder(item) {
      this.$router.push({ path: "/stock_transfer/order_create", query: { id: item.id } });
    },
    detail(item) {
      this.$router.push({ path: "/stock_transfer/order_detail", query: { id: item.id } });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的调拨单!");
        return false;
      }
      stockTransferOrdersExport({ stock_transfer_orders: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "调拨单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    summaryExportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的调拨单!");
        return false;
      }
      stockTransferOrdersSummaryExport({ stock_transfer_orders: this.selectedRowKeys }).then((resp) => {
        exportExcel(resp, "调拨单汇总");
      });
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    printOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要打印的调拨单!");
        return;
      }

      stockTransferOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("StockTransferOrder");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        for (const orderItem of data) {
          const materialItems = orderItem.stock_transfer_material_items;
          const totalQuantity = materialItems.reduce((sum, item) => NP.plus(sum, item.total_quantity), 0);
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, orderItem.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
          const barcodeBase64 = canvas.toDataURL();

          const perPageCount = 14;
          const totalPages = Math.ceil(materialItems.length / perPageCount);
          for (let index = 1; index <= totalPages; index++) {
            const pageMaterialItems = materialItems.slice((index - 1) * perPageCount, index * perPageCount);
            const tableRows = pageMaterialItems.map(
              (item) =>
                `<tr>
                  <td>${this.printText(item.material_item.number)}</td>
                  <td>${this.printText(item.material_item.name)}</td>
                  <td>${this.printText(item.material_item.english_name)}</td>
                  <td>${this.printText(item.material_item.spec)}</td>
                  <td>${this.printText(item.total_quantity)}</td>
                </tr>`
            );
            const pageContent = `
              <div style="padding: 8px">
                <div style="font-size: 36px; text-align: center">
                  <span>调拨单</span>
                  <span style="float: right;">${index}</span>
                </div>

                <div style="margin-top: 8px; text-align: right">
                  <img src="${barcodeBase64}" />
                </div>

                <div style="margin-top: 8px; display: flex">
                  <div style="width: 33%">客户: ${this.printText(orderItem.client_item.name)}</div>
                  <div style="width: 34%">调出仓库: ${this.printText(orderItem.out_warehouse_item.name)}</div>
                  <div style="width: 33%">调出库区: ${this.printText(orderItem.out_area_item.name)}</div>
                </div>

                <div style="margin-top: 8px; display: flex">
                  <div style="width: 33%">调入仓库: ${this.printText(orderItem.in_warehouse_item.name)}</div>
                  <div style="width: 34%">调入库区: ${this.printText(orderItem.in_area_item.name)}</div>
                  <div style="width: 33%">调拨总数: ${this.printText(totalQuantity)}</div>
                </div>

                <div style="margin-top: 8px; display: flex">
                  <div style="width: 33%">备注: ${this.printText(orderItem.remark)}</div>
                  <div style="width: 34%">创建人: ${this.printText(orderItem.creator_item.name)}</div>
                  <div style="width: 33%">创建时间: ${this.printText(orderItem.create_time)}</div>
                </div>

                <div style="margin-top: 16px; font-size: 14px">
                  <table border="1" cellspacing="0" style="width: 100%; text-align: center">
                    <tr>
                      <th>产品编号</th>
                      <th>产品名称</th>
                      <th>英文名称</th>
                      <th>包装规格</th>
                      <th>调拨数量</th>
                    </tr>
                    ${tableRows.join("")}
                    <tr>
                      <td>合计:</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>${this.printText(totalQuantity)}</td>
                    </tr>
                  </table>
                </div>
              </div>`;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }
        LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
