import request from "@/utils/request";

// StockTransferOrder
export function stockTransferOrderList(params) {
  return request({ url: `/stock_transfer_orders/`, method: "get", params });
}

export function stockTransferOrderRetrieve(params) {
  return request({ url: `/stock_transfer_orders/${params.id}/`, method: "get", params });
}

export function stockTransferOrderCreate(data) {
  return request({ url: `/stock_transfer_orders/`, method: "post", data });
}

export function stockTransferOrderMaterials(params) {
  return request({ url: `/stock_transfer_orders/${params.id}/stock_transfer_materials/`, method: "get", params });
}

export function stockTransferOrderVoid(data) {
  return request({ url: `/stock_transfer_orders/${data.id}/void/`, method: "post", data });
}

// StockTransferMaterial
export function stockTransferMaterialList(params) {
  return request({ url: `/stock_transfer_materials/`, method: "get", params });
}

// 调拨产品
export function transferMaterials(params) {
  return request({ url: `/materials/options/`, method: "get", params });
}

// LocationTransferRecord
export function locationTransferRecordList(params) {
  return request({ url: `/location_transfer_records/`, method: "get", params });
}

export function locationTransferRecordCreate(data) {
  return request({ url: `/location_transfer_records/`, method: "post", data });
}

export function locationTransferRecordVoid(data) {
  return request({ url: `/location_transfer_records/${data.id}/void/`, method: "post", data });
}

export function locationTransferRecordImport(data) {
  return request({
    url: "/location_transfer_records/import_data/",
    method: "post",
    data,
  });
}

export function locationTransferRecordTemplate(params) {
  return request({
    url: "/location_transfer_records/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function locationTransferRecordExport(params) {
  return request({
    url: "/location_transfer_records/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function stockTransferOrderPrint(data) {
  return request({ url: `/stock_transfer_orders/print_data/`, method: "post", data });
}
